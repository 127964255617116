.login-container {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    max-width: 360px;
    margin: 0 auto;
  }
  
  .login-form {
    display: grid;
    gap: 10px;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 10px;
    align-items: center;
  }
  
  .form-row label {
    width: 100px;
    font-weight: bold;
  }
  
  .login-form input {
    padding: 10px;
    border-radius: 5px;
  }
  
  .login-form input:focus {
    outline: none;
    border-color: #af42ee;
  }
  
  .login-button {
    background-color: #af42ee;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #8a2be2;
  }
  
  .sign-up-link {
    margin-top: 10px;
  }
  
  .sign-up-link label {
    color: #333;
  }
  
  .sign-up-link a {
    color: #1d0ea7;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .sign-up-link a:hover {
    color: #05031a;
  }

  .forgot-password-link a {
    color: #1d0ea7;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .forgot-password-link a:hover {
    color: #05031a;
  }
  