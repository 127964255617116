
.circles {
    width: 100%;
    height: 100vh;
    position: absolute;
  }
  .circles div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .circle1 {
    width: 280px !important;
    height: 280px !important;
    top: 10%;
    left: 10%;
    z-index: 1;
    opacity: 0.25;
    background: #fff;
    border-radius: 50%;
  }
  .circle2 {
    width: 100px !important;
    height: 100px !important;
    bottom: 20%;
    left: 12%;
    z-index: 2;
    opacity: 0.3;
    background: #fff;
    border-radius: 50%;
  }
  .circle3 {
    width: 200px !important;
    height: 200px !important;
    bottom: 34%;
    left: 2%;
    z-index: 3;
    opacity: 0.25;
    background: #fff;
    border-radius: 50%;
  }
  .circle4 {
    width: 200px !important;
    height: 200px !important;
    top: 15%;
    right: 10%;
    z-index: 4;
    opacity: 0.25;
    background: #fff;
    border-radius: 50%;
  }
  .circle5 {
    width: 400px !important;
    height: 400px !important;
    bottom: 24%;
    right: 10%;
    z-index: 5;
    opacity: 0.2;
    background: #fff;
    border-radius: 50%;
  }
  .circle6 {
    width: 50px !important;
    height: 50px !important;
    bottom: 10%;
    right: 14%;
    z-index: 6;
    opacity: 0.3;
    background: #fff;
    border-radius: 50%;
  }
  .wavesContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100px;
  }
  .waves {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    fill: #0e49a9;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
    fill-opacity: 0.7;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
    fill-opacity: 0.5;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
    fill-opacity: 0.3;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
    fill-opacity: 1;
  }
  .sun {
    width: 500px;
    height: 250px;
    position: absolute;
    bottom: 0px;
    background: #fff;
    border-radius: 500px 500px 0 0 ;
    box-shadow: 0 0 50px;
  }

  @media (max-width: 768px) {
    
    .sun {
      width: 300px;
      height: 150px;
      position: absolute;
      bottom: 0px;
    }
    .circles {
        width: 90vw;
        height: 100vh;
        position: absolute;
      }
    .circle1 {
        width: 150px !important;
        height: 150px !important;
        top: 10vh;
        left: 0vw;
      }
      .circle2 {
        width: 50px !important;
        height: 50px !important;
        bottom: 25vh;
        left: 2vw;
      }
      .circle3 {
        width: 100px !important;
        height: 100px !important;
        bottom: 34vh;
        left: 4vw;
      }
      .circle4 {
        width: 100px !important;
        height: 100px !important;
        top: 15vh;
        right: 6vw;
      }
      .circle5 {
        width: 200px !important;
        height: 200px !important;
        bottom: 38vh;
        right: 0vw;
      }
      .circle6 {
        width: 50px !important;
        height: 50px !important;
        bottom: 15vh;
        right: 4vw;
      }
  }

  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }