.grid-container-ap {
  display: flex;
  justify-content: center;
  width: 100%;
}

.atr-list {
  list-style: none;
  padding: 0;
  display: grid;
}

@media screen and (min-width: 600px) {
  .atr-list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    min-width: 900px;
  }
}

@media screen and (max-width: 768px) {
  .atr-list {
    display: none;
  }

  .show-list {
    display: block !important;
  }
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder-boxes {
  justify-content: space-around;
}

.placeholder-box {
  background-color: #ccc;
  margin: 0 5px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  width: 200px;
  height: 90px;
  padding: 10px;
  animation: floatAnimation 1s infinite alternate ease-in-out, loadingAnimation 2s infinite linear;
}

@keyframes loadingAnimation {
  0% {
    transform: scaleX(.5);
  }
  50% {
    transform: scaleX(.75);
  }
  100% {
    transform: scaleX(.9);
  }
}

.title-Container {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.placeholder-cards {
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align cards to the bottom of the container */
}

.placeholder-card {
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  width: 400px; /* Adjust card width as needed */
  height: 400px; /* Adjust card height as needed */
  background-color: #ccc; /* Placeholder color */
  margin: 10px; /* Adjust margin between cards as needed */
  animation: floatAnimation 1s infinite alternate ease-in-out, loadingAnimation 2s infinite linear;
}

.cal-Header{
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder-calendar {
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  width: 400px; /* Adjust card width as needed */
  height: 20px; /* Adjust card height as needed */
  background-color: #ccc; /* Placeholder color */
  margin: 10px; /* Adjust margin between cards as needed */
  animation: floatAnimation 1s infinite alternate ease-in-out, loadingAnimation 2s infinite linear;
}

.filter-Desc-text{
  display: flex;
  justify-content: left; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 80%;
}

.filter-icon-container{
  display: flex;
  justify-content: left; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-bottom: 25px;
}

.filter-icon {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(to bottom left, skyblue 0%, #be80f4 60%, #f8b268 80%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

/* Media query for mobile view */
@media screen and (max-width: 768px) {
  /* Hide the grid on mobile view */
  .grid-ap {
    display: none;
  }
  
  .filter-icon-container{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin-bottom: 25px;
  }

  /* Show the filter icon on mobile view */
  .filter-icon {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(to bottom left, skyblue 0%, #be80f4 60%, #f8b268 80%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
  }
  
.description-box{
  min-width: 25%;
}
}

.apply-button-container  {
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button {
  display: flex;
  justify-content: center;
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 40%, #ffaa51 100%);
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
}

.apply-button:disabled {
  background: #cccccc; /* Gray background for disabled state */
  color: black; /* Gray text for disabled state */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}

.description-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 40%, #ffaa51 100%);
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  border: none;
  border-radius: 8px;
  height: auto;
  padding: 5px;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.decision-card-Stack{
  display: flex;
  justify-content: center;
}

.atr-loc-picker{
padding: 10px;
}