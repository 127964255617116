.grid-container-msd {
  display: grid;
  grid-template-rows: auto auto; /* Two rows, first row for label, second row for selected options */
  justify-content: center;
  width: 100%;
}

.grid-msd {
  width: 100%;
}

.input-container-msd {
  display: flex;
  gap: 10px; /* Adjust gap between elements */
}

.label-msd {
  font-weight: bold;
}

.selected-options-container-msd {
  display: flex;
  flex-wrap: wrap; /* Wrap items to the next row when there is no space */
}

/* Styling for Selected Options as Bubbles */
.selected-option-msd {
  background-color: #1d0ea7; /* Bubble background color */
  color: #fff; /* Text color */
  padding: 5px 10px;
  border-radius: 20px; /* Rounded corners to create a bubble effect */
  margin-right: 5px; /* Adjust margin between bubbles */
  margin-bottom: 5px; /* Adjust margin between rows */
  display: inline-flex; /* Display selected options in a single row */
  cursor: pointer;
}

.selected-option-msd:hover {
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5); /* Darker background color on hover */
}

.dropdown-arrow-msd {
  padding-left: 10px;
  cursor: pointer;
}

.options-list-msd {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
}

.option-msd {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  cursor: pointer;
  padding: 5px 0;
}

.option-msd input[type="checkbox"] {
  margin-right: 10px;
}

.option-msd span {
  font-size: 14px;
  color: #333;
}

/* MultiSelectDropdown.css */

.buton-container-rbi {
  display: flex;
  flex-direction: column;
}

.button-toadd-rbi {
  display: grid;
  grid-gap: 10px; /* Adjust gap between buttons */
  overflow-y: auto; /* Add scrollbar if buttons exceed maximum height */
}


.button-toadd-rbi button {
  width: 100%; /* Ensure buttons take up full width of grid cell */
  padding: 10px 20px; /* Adjust button padding */
  border: none;
  border-radius: 5px;
  background-color: #1d0ea7; /* Standard button background color */
  color: #fff; /* Standard button text color */
  font-size: 16px; /* Standard button font size */
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-toadd-rbi button:hover {
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5); /* Darker background color on hover */
}

.selected-count-bubble {
  background-color: green;
  color: white;
  border-radius: 50%;
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
}