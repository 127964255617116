/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure modal is on top of other elements */
  }
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 90%; /* Adjust width to fit within viewport */
    max-width: 600px; /* Maximum width for larger screens */
    max-height: 90%;
    position: relative; /* Ensure modal can be positioned relative to the overlay */
    overflow-y: auto;
  }

  @media screen and (max-width: 480px) {
    .modal {
      width: calc(100% - 20px); /* Adjust width to fit within viewport with some margin */
      max-width: none; /* Remove maximum width for smaller screens */
      margin: 10px; /* Add margin to center modal on smaller screens */
    }
  }
  
  .close-button {
    position: absolute;
    width: 25px;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #555;
    padding: 5px; /* Add padding to make the clickable area smaller */
  }

  .close-button:hover {
    color: #8a2be2; /* Change color on hover */
  }
  
  h2 {
    margin-top: 0;
  }
  
  .modal-style{
    display: block;
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .modal-button {
    background-color: #af42ee;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .modal-button :hover {
    background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
  }
  
  .youtube-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main-video-container {
    width: 100%;
    max-width: 900px; /* Adjust as needed */
    aspect-ratio: 16 / 9;
    position: relative;
  }
  
  .main-video-container iframe {
    width: 100%;
    height: 100%;
    padding-top: 20px;
  }
  
  .vp-thumbnail-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .vp-thumbnail {
    width: 120px; /* Adjust as needed */
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .vp-thumbnail:hover {
    transform: scale(1.1);
  }
  
  .vp-thumbnail.selected {
    border: 2px solid #007bff;
  }
  
  /* Mobile-Specific Header Adjustments */
@media screen and (max-width: 768px) {
  .modal {
    max-width: 400px; /* Maximum width for larger screens */
  }
}