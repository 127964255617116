/* General styles for the ActionGrid component */
.ag-expense-income-card {
    font-family: Arial, sans-serif;
    margin: 0; /* Remove margin for full viewport usage */
}

.ag-grid-container {
    width: 900px; /* Set width to 900px */
    height: 90vh; /* Set height to fit within viewport */
    overflow-x: auto; /* Enable horizontal scroll if necessary */
    overflow-y: auto; /* Enable vertical scroll if needed */
}

.ag-grid-header {
    background-color: #fce4ec; /* Very light baby pink background */
    font-weight: bold;
    display: flex;
}

.ag-header-cell {
    display: flex;
    align-items: center;
    justify-content: center; /* Center align header text */
    padding: 5px; /* Reduced padding for smaller header size */
}

/* Fixed width for header and data cells */
.ag-bordered-grid {
    border: 1px solid #ddd;
    min-width: 120px; /* Fixed minimum width for alignment */
}

.ag-header-cell,
.ag-data-cell {
    width: 120px; /* Fixed width for alignment */
}

.ag-header-text {
    white-space: nowrap; /* Prevent text wrapping */
    cursor: pointer; /* Make header text clickable */
}

.ag-data-cell {
    padding: 10px;
    text-align: center;
}

.ag-alternate-row {
    background-color: #f9f9f9; /* Light gray for alternate rows */
}

.ag-grid-row {
    display: flex; 
}
