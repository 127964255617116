.two-column-container {
    display: flex;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
  }
  
  .left-column {
    border-right: 3px solid #ddd;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }
  
  .right-column {
    padding: 10px;
    box-sizing: border-box;
  }
  
  .atr-list2 {
    list-style: none;
    padding: 0;
    margin: 10px;
  }
  
  .filter-options{
    display: flex; /* Aligns children side by side */
    align-items: center; /* Vertically aligns items in the center */
    gap: 10px; /* Adds space between the text and the bubble */
    padding: 10px 15px; /* Adds padding for spacing */
    border: 1px solid #ddd; /* Adds a light border */
    border-radius: 4px; /* Slightly rounds the corners */
    background-color: #f9f9f9; /* Light background color */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth hover transition */
  }
  
  .filter-options:hover {
    background: linear-gradient(to bottom left, skyblue 0%, #be80f4 60%, #f8b268 80%);
    box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
    cursor: pointer; /* Changes the cursor to indicate interactivity */
  }

  .filter-options.selected {
    background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
    box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
    color: white;
  }