.card {
  position: relative;
  width: 900px; /* Adjust width as needed */
  height: auto; /* Adjust height as needed */
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Match login page box shadow */
  overflow: hidden;
  margin-bottom: 20px; /* Add margin between cards */
}

/* Media query for desktop screens */
@media screen and (min-width: 900px) {
  .card {
    min-width: 900px; /* Limit maximum width to match login container */
  }
}

@media screen and (max-width: 768px) {
  .card {
    /* Override min-width for mobile */
    max-width: unset;
    max-width: 96vw; /* Remove maximum width for mobile */
    padding: 5px; /* Adjust padding for mobile */
  }
}

.card-content {
  padding: 20px;
  /*color: #af42ee; /* Match login page title color */
  font-size: 24px; /* Match login page title font size */
  font-weight: bold; /* Match login page title font weight */
  overflow-wrap: break-word; /* Wrap long lines */
  max-width: 100%; /* Limit the maximum width to the viewport width */
}

.card-content pre {
  white-space: pre-wrap; /* Preserve whitespace and wrap lines */
  word-wrap: break-word; /* Allow long words to break */
  overflow-wrap: break-word; /* Wrap long lines */
  font-family: inherit;
}

.card-buttons {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  flex-wrap: wrap;
}

.card-buttons button {
  padding: 10px 20px;
  margin: 0 5px; /* Add margin between buttons */
  border: none;
  border-radius: 5px;
  background-color: #1d0ea7; /* Match login page button color */
  color: #fff; /* Match login page button text color */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* Make buttons equal size */
  flex-basis: 20%; /* Initial width of each child component */
  flex-grow: 1; /* Make each child component expand to fill available space */
}

.card-buttons button:hover {
  background-color: #8a2be2; /* Darker background color on hover */
}

@keyframes stackUp {
  0% {
    opacity: 0;
    transform: translateY(50px) scale(0.9); /* Adjust translation and scale as needed */
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1); /* Adjust translation and scale as needed */
  }
}

.card:nth-child(odd) {
  animation: stackUp 0.5s ease-in-out forwards; /* Adjust animation duration and timing as needed */
}

.card:nth-child(even) {
  animation: stackUp 0.5s ease-in-out forwards; /* Adjust animation duration and timing as needed */
}

.view-link-container {
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  cursor: pointer;
}

.food-titlerow-cal-icon{
  display: flex;
  align-items: center;
}

.gradient-button {
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 40%, #ffaa51 100%);
  color: white !important;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.gradient-button:hover {
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
}

.wc-Picker-grid{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 95%;
}

.wc-card-buttons{
  padding: 10px;
}