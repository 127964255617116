/* src/components/HomePage.css */

.homepage-container {
  width: 100vw;  /* Set the width to 100% of the viewport */
  margin: 0;     /* Remove any margin to ensure it's fully stretched */
  padding: 0;    /* Remove any padding if necessary */
  }
  
  .hp-grid-container {
    width: 100%;
  }

  
  
  