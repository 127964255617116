.section1-container {
    width: inherit;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    position: relative;
    height: 97vh;
    scroll-behavior: smooth;
  }
  
  .header {
    position: relative;
    text-align: center;
    background: linear-gradient(180deg, #e0f3ff 0%, #f3d9ff 50%, #ffe8cc 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .intro{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    padding: 20px;
  }
  
  .hp-img {
    width: 100px;
    height:120px;
  }

  .hp-img-container{
    flex: 0 0 auto;
  }

  .header h1 {
    font-size: 40px;
    position: relative;
    font-family: Pacifico, cursive;
    color: #333;
  }

  .header h2 {
    font-size: 22px;
    font-weight: 500;
    position: relative;
    font-family: 'Lato', sans-serif;
    padding: 15px;
  }

  .hp-menu{
    z-index: 50;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .header h1 {
      margin-top: 10px;
      font-size: 25px;
    }
    .header h2 {
      font-size: 18px;
      padding: 3px;
    }

    .header{
      min-height: 100vh;
    }
    
    .section1-container {
      height: 90vh;
    }
  }
  
  @keyframes scrollUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.scroll-item {
  opacity: 0;
  transform: translateY(100%);
}

.scroll-item.visible {
  animation: scrollUp 1s forwards;
}