.loading-animation-activate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .placeholder-boxes-activate {
    justify-content: space-around;
  }
  
  .placeholder-box-activate {
    background-color: #ccc;
    margin: 0 5px;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 8px;
    width: 400px;
    height: 90px;
    padding: 10px;
    animation: floatAnimation 1s infinite alternate ease-in-out, loadingAnimation 2s infinite linear;
  }
  
  @keyframes loadingAnimation {
    0% {
      transform: scaleX(.5);
    }
    50% {
      transform: scaleX(.75);
    }
    100% {
      transform: scaleX(.9);
    }
  }