/* ExpenseIncomeCard.css */

.expense-income-card {
    position: relative;
    width: 100%; /* Adjust width as needed */
    height: auto; /* Adjust height as needed */
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* Match login page box shadow */
    overflow: hidden;
    margin-bottom: 20px; /* Add margin between cards */
  }
  
  
/* Media query for desktop screens */
@media screen and (min-width: 900px) {
    .expense-income-card {
      min-width: 900px; /* Limit maximum width to match login container */
    }

    .eic-column {
        min-width: 375px;
    }

    .eic-column-fullwidth {
      min-width: 100%;
  }
  }
  
  @media screen and (max-width: 768px) {
    .expense-income-card {
      /* Override min-width for mobile */
      max-width: unset;
      max-width: 97%; /* Remove maximum width for mobile */
      padding: 5px; /* Adjust padding for mobile */
    }

    .eic-column {
        min-width: 175px;
    }

    .eic-column-fullwidth {
      min-width: 100%;
  }
  }
  
  .eic-card-body {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    /*color: #af42ee; /* Match login page title color */
    font-weight: bold; /* Match login page title font weight */
  }
  
  .eic-column {
    min-width: 175px;
    width: 45%; /* Adjust as needed */
  }

  .eic-column-fullwidth {
    min-width: 100%;
    width: 100%; /* Adjust as needed */
  }
  
  .eic-column h2 {
    margin-bottom: 10px;
  }

  .eic-column-fullwidth h2 {
    margin-bottom: 10px;
  }
  
  .eic-sub-grid {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }
  
  .eic-sub-grid-header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .eic-sub-grid-row {
    display: flex;
    font-weight: normal;
    color: #000000;
    font-size: 15px;
    justify-content: space-between;
  }
  
  .eic-sub-grid-row:first-child {
    border-top: none; /* Remove top border from the first row */
  }
  
  
  .eic-sub-grid-row > div:last-child {
    border-right: none; /* Remove right border from the last column */
  }
  
  .eic-sub-grid-row > .eic-sub-grid-column {
    max-width: calc(100% / 3); /* Limit column width to 1/3 of the container */
    box-sizing: border-box; /* Include borders in the width calculation */
    border: 1px solid #ccc; 
    padding: 0 10px; /* Add padding to the columns */
}

.eic-sub-grid-row > .eic-sub-gridcolumn:last-child {
    border-right: none; /* Remove right border from the last column */
}

.eic-sub-grid-image-box {
    width: 50px; /* Set max width */
    height: 50px; /* Set max height */
    overflow: hidden; /* Hide overflowing content */
    border-radius: 5px; /* Add border radius */
}

.eic-sub-grid-image-box img {
    width: 100%; /* Ensure image fills the container */
    height: 100%; /* Ensure image fills the container */
    object-fit: cover; /* Ensure image covers the container */
}
  
  @keyframes stackUp {
    0% {
      opacity: 0;
      transform: translateY(50px) scale(0.9); /* Adjust translation and scale as needed */
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1); /* Adjust translation and scale as needed */
    }
  }
  
  .expense-income-card:nth-child(odd) {
    animation: stackUp 0.5s ease-in-out forwards; /* Adjust animation duration and timing as needed */
  }
  
  .expense-income-card:nth-child(even) {
    animation: stackUp 0.5s ease-in-out forwards; /* Adjust animation duration and timing as needed */
  }

.centered-text {
  text-align: center;
}
