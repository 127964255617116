/* CustomTagView.css */
.custom-tag-view {
    padding: 20px;
}

.tag-group {
    margin-bottom: 20px;
}

.tag-group h3 {
    margin: 0;
    padding: 10px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
}

.tag-group ul {
    list-style: none;
    padding: 0;
}

.tag-group li {
    padding: 10px;
    border: 1px solid #ddd;
    cursor: pointer;
    margin-bottom: 5px;
}

.tag-group li:hover {
    background-color: #f9f9f9;
}

/* Hide the navigation buttons */
.rbc-toolbar .rbc-btn-group span:nth-child(1), 
.rbc-toolbar .rbc-btn-group span:nth-child(2) {
    display: none;
}
