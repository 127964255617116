/* SwipeCards.css */
.card-stack {
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
}

.ai-warrning{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: small;
  margin-top: 5px;
}