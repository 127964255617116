.location-form {
    display: grid;
    gap: 10px;
}

.location-form div {
    display: flex;
    align-items: center;
}

.location-form label {
    margin-right: 10px;
}

.next-button {
    align-self: flex-end;
    padding: 10px 20px;
    font-size: 1.2rem;
}

