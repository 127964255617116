.control {
    display: grid;
    position: relative;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); 
    width: 900px; /* Adjust width as needed */
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    .control {
      /* Override min-width for mobile */
      max-width: unset;
      max-width: 380px; /* Remove maximum width for mobile */
      padding: 5px; /* Adjust padding for mobile */
    }

    .image-column {
        display: flex; /* Use flexbox */
        justify-content: center; /* Center align horizontally */
        align-items: center; /* Center align vertically */
    }
    
    .icon-circle {
      width: 55px;
      height: 55px;
    }
  }
  
  .image-column img {
    max-width: 300px;
    height: 450px;
    object-fit: contain;
    padding: 10px;
  }

  .image-column {
    border-radius: 40px; /* Apply border radius to image-column */
    overflow: hidden; /* Hide overflowing content (e.g., if the image is larger than the container) */
  }
  
  .overview {
    padding: 10px;
  }

  .content-column {
    display: flex;
    flex-direction: column;
  }
  
  .title {
    font-weight: bold;
    font-size: 40px;
    padding: 10px;
    color: black;
  }

  .genre {
    font-weight: 500;
    padding-left: 10px;
  }
  
  .metadata {
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .metadata span {
    font-weight: 500;
    padding: 10px;
  }

  .play-trailer-icon {
    align-items: center;
    justify-content: center;
    background-color: #1d0ea7;
    border-radius: 50%;
    cursor: pointer;
  }

  .play-trailer-icon:hover {
    background-color: #8a2be2;
  }

  .icons {
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  .food-icons {
    /* display: flex; */
    display: none;
    justify-content: center;
    padding: 10px;
  }
  
  .overview {
    font-weight: 500;
  }

  .icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d0ea7;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    cursor: pointer;
    margin: 0 20px;
  }
  
  .icon-circle:hover {
    background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
  }
  
  .icon-circle:hover .history-icon {
    display: block;
  }

  .icon-small-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d0ea7;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .icon-small-circle:hover {
    background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
  }
  
  .icon-small-circle:hover .history-icon {
    display: block;
  }
  
  .history-icon {
    display: none;
    position: absolute;
    color: #fff;
  }

.bg-red {
    background-color: #cd3a3a;
}

.bg-map {
  background-color: #ed8003;
}

.bg-green {
    background-color: #24875b;
}

.bg-blue {
    background-color: #1d0ea7;
}

.bg-red:hover {
  background-color: #8a0303;
}

.bg-map:hover {
  background-color: #cd3a3a;
}

.bg-green:hover {
  background-color: #024f2c;
}

.bg-blue:hover {
  background-color: #0c026b;
}

.bg-gradient {
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 40%, #ffaa51 100%);
}

.bg-gradient:hover {
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
}
  
  @media screen and (max-width: 768px) {
    .control {
      grid-template-columns: 1fr;
    }
  
    .image-column img {
      width: 100%;
    }
  }
  