.repeatable-box-list {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 900px;
  margin: 0 auto;
}

.box-list {
  list-style: none;
  padding: 0;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Dynamically adjust columns */
}

.repeatable-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 40%, #ffaa51 100%);
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  border: none;
  border-radius: 8px;
  width: 175px;
  height: 100px;
  padding: 10px;
  cursor: pointer;
}

.repeatable-box:hover {
  background: linear-gradient(to top right, #3a5bb7 0%, #8f3bdb 65%, #ffaa51 100%);
  box-shadow: 0 0 0 4px rgba(102, 244, 74, 0.5);
}

.box-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.box-text {
  font-size: 16px;
  color: #fff;
  text-align: center;
}

.placeholder-boxes {
  display: flex;
  justify-content: space-around;
}

.placeholder-box {
  background-color: #ccc;
  margin: 0 5px;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  width: 200px;
  height: 90px;
  padding: 10px;
  animation: floatAnimation 1s infinite alternate ease-in-out, loadingAnimation 2s infinite linear;
}

@keyframes loadingAnimation {
  0% {
    transform: scaleX(.5);
  }
  50% {
    transform: scaleX(.75);
  }
  100% {
    transform: scaleX(.9);
  }
}

.search-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
  margin-bottom: 10px; /* Add margin to separate from the list */
  box-sizing: border-box; 
}

.search-input:focus {
  outline: none;
  border-color: #af42ee;
}

@media screen and (min-width: 600px) {
  .box-list {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .repeatable-box {
    width: 175px;
  }
}