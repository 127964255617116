/* HPSwipingCards.css */
.swiper {
    width: 90%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
  }

  .custom-grid {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    color: #fff; /* Default text color */
    background-size: cover; /* Ensure the background image covers the entire container */
    background-position: center;
    background-repeat: no-repeat;
}

.custom-grid::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--bg-image);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.3; /* Adjust opacity to make the image less prominent */
    z-index: -1;
    border-radius: 8px;
}

.custom-grid::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black overlay */
    z-index: 2;
    border-radius: 8px;
}

.title-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust as necessary for alignment */
  z-index: 3;
  position: relative;
}

.hp-title-cards {
    font-size: 28px;
    font-weight: 700;
    z-index: 3;
    color: #fff;
    flex-grow: 1; /* Ensure the title takes up the remaining space */
}

.hp-icon-card {
    align-items: center;
    z-index: 3;
    width: 60px !important;
    height: 60px !important;
    display: inline-block
}

.description {
    font-size: 18px;
    font-weight: 400;
    z-index: 3;
    position: relative;
    color: #ddd;
}

.orcas{
  background: #0e49a9;
}

.orcas .two .flexText {
  padding-top: 100px;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: space-around;
}

.flexText h1 {
  font-size: 40px;
  font-weight: 600;
  border-bottom: 2px solid #ffffff4f;
  margin: 0 auto 10px;
  padding-bottom: 10px;
  color: black;
}