/* Header Container */
.header-container {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;  /* Fixed background color issue */
  position: relative;
  color: white;
}

/* Left Bar */
.left-bar {
  height: 85%;
  background: linear-gradient(to bottom left, skyblue 0%, #be80f4 60%, #f8b268 80%);
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;  /* Adjust for right padding */
  border-radius: 0 50px 50px 0; /* Semi-circle effect at the right edge */
  width: auto; /* Dynamic width */
}

/* Logo Container */
.logo-container {
  width: 60px; /* Adjust size for mobile */
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Logo */
.logo {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the logo scales properly */
}

/* Site Title */
.title-site {
  flex-grow: 1;
  font-size: 2.0em;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  margin-left: 10px;
  white-space: nowrap;
}

.bottom-header{
  padding: 10px;
}

/* Middle Area */
.middle-area {
  flex-grow: 1;
  background: white; /* White background */
}

/* Circle Container for Hamburger */
.circle-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(to bottom left, skyblue 0%, #be80f4 60%, #f8b268 80%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1000;
}

/* Hamburger Icon */
.hamburger-icon {
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
}

/* Flyout Menu */
.menu-flyout {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background: linear-gradient(to bottom left, skyblue 0%, #be80f4 60%, #f8b268 80%);
  transition: right 0.3s ease;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.menu-flyout.show {
  right: 0;
}

/* Menu Items */
.menu-item {
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.menu-item a, .menu-item button {
  text-decoration: none;
  color: #333;
  font-size: 1.1em;
}

/* Menu Overlay */
.menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.menu-overlay.show {
  display: block;
}

/* Mobile-Specific Header Adjustments */
@media screen and (max-width: 768px) {
  .header-container {
    height: 100px; /* Adjust header height for mobile */
  }

  .circle-container {
    width: 50px;
    height: 50px;
  }

  .logo-container {
    width: 55px; /* Adjust size for mobile */
    height: 55px;
    min-width: none;
  }

  .title-site {
    font-size: 1.25em;  /* Adjust font size for mobile */
  }
}
