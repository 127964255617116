/* Set background color */
body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: top;
    min-height: 100vh;
  }
  
  /* Apply font to specific elements */
  h1, h2, h3, h4, h5, h6, label {
    font-family: 'Cosmic Octo', sans-serif;
    color: #000000;
  }
  
  /* Define content area */
  .content {
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    color: white;
    width: 100%; /* Set width to 100% to utilize full width */
    max-width: 1600px; /* Optional: Add a max-width to prevent content from stretching too wide on larger screens */
    margin: 0 auto; /* Center the content horizontally */
  }
  
  /* Media query for mobile devices */
  @media screen and (max-width: 768px) {
    .content {
      width: 100%; /* Adjust width to match device width */
    }
  }
  
  /* Media query for desktops */
@media screen and (min-width: 900px) {
    .cosmic-octo-heading {
        min-width: 900px; /* Ensure minimum width for desktop */
        margin: 0 auto; /* Center the grid on desktop */
    }
  }